.svg_class{
    /* width: 100px;
    height: 29px;
    margin: 20px;
    display:inline-block;
    margin-top: -7px;
    margin-left: -30px;
    margin-right: -40px; */
    width: 114px;
    height: 35px;
    margin: 20px;
    display: inline-block;
    margin-top: -9px;
    margin-left: -30px;
    margin-right: -40px;
    padding-top: -20px;
    margin-bottom: -3px;
  }
  
  .c-wrapper:not(.c-wrapper-fluid) .c-header-fixed{
    margin-top: -20px;
  }

.login_bt {
    display: flex;
    height: 32px;
}


.success_color{
  background-color: #c4d1c0;
  color:#45484d
}


.error_color{
  background-color: #df7282;
  color:#45484d
}

.validationErrorMessagesStyles {
  margin-top: -43px;
  margin-bottom: 5px;
  font-size: 11px;
}



.validationRegisterErrorMessagesStyles {
  margin-top: -57px;
  margin-bottom: 17px;
  font-size: 11px;
  color: red;
}


.input_class{
  margin-bottom: 4rem !important;
}



.thead{
      background-color: #dcdfe4
}

/* .loader{
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
} */

/*
  Set the color of the icon
*/
/* svg path,
svg rect{
  fill: #FF6700;
} */


/* SVG spinner icon animation */
.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 5s linear infinite;
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  margin:46% 16% 31% 89%;
  width: 50px;
  height: 50px;
}

.spinner-new {
  color:#3c4b64 !important;
  animation: rotate 1.1s linear infinite;
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 46% 16% 31% 89%;
  /* width: 50px; */
  /* height: 50px; */
}


.spinner .path {
  stroke: #9486e1;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

.loader--style2{
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 5s linear infinite;
z-index: 20;
position: absolute;
top: 50%;
left: 157%;
width: 50px;
height: 50px;
}


.spinner_text1{
  z-index: 20;
  position: absolute;
  top: 148%;
  left: 149%;
  width: 100%;
  height: 50px;
  color: black;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}



.spinner_text{
  z-index: 20;
  position: absolute;
  top: 148%;
  left: 50%;
  margin:44% 15% 34% 83%;
  width: 100%;
  height: 50px;
  color: black;
}

.dzu-dropzone{
  height: 189px;
}

.upload_doc {
  margin-top: 25px;
  margin-bottom: 54px;
}

/* .dzu-dropzone .dzu-inputLabelWithFiles .dzu-input{
  display: none;
}



.dzu-inputLabelWithFiles {
 
  margin-left: 46%;
  margin-top: 4%;
} */
.sample_doc{
  float: right;
  margin-right: 0%;
  margin-top: -43px;
}

.no_call_details{
  margin-left: 46%;
  font-weight: 800;
  font-size: 20px;
  font-family: Roboto;
}

.dzu-inputLabelWithFiles{
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: flex-start; */
  padding: 0 14px;
  min-height: 32px;
  background-color: #E6E6E6;
  color: #2484FF;
  border: none;
  font-family: 'Helvetica', sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 62px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}



table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
bottom: .5em;
}

.custom_css{
  position: relative;
  overflow-y: scroll
}


.error_class {
  background-color: rgb(210 87 40);
  color: antiquewhite;
}

.toast_class {
  background-color: rgb(86 140 208);
  color: antiquewhite;
}


.refresh_icon{
  float: right;
  margin-top: -28px;
  margin-right: 14px;
  font-size: 20px;
  cursor: pointer;
}

.detail-text-color{
  color: #484848;
  pointer-events: none;
}

.view-text-color{
  color: #484848;
}

.success_msg {
  background-color: #d4edda;
  color: #2b5033;
}

.reset_msg_note{
  margin-right: auto;
  font-size: 11px;
}


.call_css{
  font-size: 23px;
    padding: 10px;
}


.bg-gradient-danger{
  background: linear-gradient(45deg, #82a9ab 0%, #7082dc 100%) !important;
  border-color: #5c88a2 !important;

}


.lebal_count {
  margin-left: 70px;
}

.lebal_count_inactive {
  margin-left: 56px;
}


.dashboard_loading{
  margin-top: 50px;
  z-index: 10000;
  position: fixed;
  z-index: 9999;
  margin-left: 8%;
}

.ps{
  overflow-anchor: none;
  touch-action: auto;
  overflow: scroll !important;
}


.ai_setting_details{
  pointer-events: none;
}

.call_in_progress{
  background-color: #3c843c;
  color: antiquewhite;
}

.call_success{
  background-color: rgb(86 140 208);
  color: antiquewhite;
}

.call_fail{
  background-color: rgb(210 87 40);
  color: antiquewhite;
}




  .dot {
    display: inline;
    margin-left: 0.2em;
    /* margin-right: 0.2em; */
    position: relative;
    /* top: -1em; */
    /* font-size: 3.5em; */
    opacity: 0;
    margin-right: 10px;
    animation: showHideDot 2.5s ease-in-out infinite;

    &.one { animation-delay: 0.2s; }
    &.two { animation-delay: 0.4s; }
    &.three { animation-delay: 0.6s; }
  }
@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}



.pinkBg {
    background-color: #ed184f!important;
    background-image: linear-gradient(90deg, #fd5581, #fd8b55);
}
.intro-banner-vdo-play-btn{
    height:60px;
    width:60px;
    position:absolute;
    top:50%;
    left:50%;
    text-align:center;
    margin:-30px 0 0 -30px;
    border-radius:100px;
    z-index:1;
    margin-left: 45%;
    /* height: 31px;
    width: 28px;
    position: absolute;

    left: 98%;
    text-align: center;

    border-radius: 100px;
    z-index: 1; */

}
.intro-banner-vdo-play-btn i{
    line-height:56px;
    font-size:30px
}
.intro-banner-vdo-play-btn .ripple{
    position:absolute;
    width:160px;
    height:160px;
    z-index:-1;
    left:50%;
    top:50%;
    opacity:0;
    margin:-80px 0 0 -80px;
    border-radius:100px;
      -webkit-animation:ripple 1.8s infinite;
    /* position: absolute;
    width: 69px;
    height: 58px;
    z-index: -1;
    left: 50%;
    top: 133%;
    opacity: 0;
    margin: -65px 19px -9px -81px;
    border-radius: 100px;
    -webkit-animation:ripple 1.8s infinite;
    animation:ripple 1.8s infinite */
}

@-webkit-keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
@keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(0)
    }
    100%{
        opacity:0;
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2){
    animation-delay:.3s;
    -webkit-animation-delay:.3s
}
.intro-banner-vdo-play-btn .ripple:nth-child(3){
    animation-delay:.6s;
    -webkit-animation-delay:.6s
}


.spinner-new1 {
  animation: rotate 1.1s linear infinite;
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 10% 5% 44% 100%;
  color: #3c4b64 !important;
}

.spinner_text1 {
  z-index: 20;
  position: absolute;
  top: 148%;
  left: 50%;
  margin: 7% 2% 54% 90%;
  width: 100%;
  height: 50px;
  color: black;
}

.call_verify{
  background-color: #d6ebff;
  color: #1b508f;

}


.call_message{
    top: 00px,;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    /* background-color : rgba(116, 129, 140, .9); */
    padding-bottom: 5px;
    padding-right: 1rem;
    margin-top: -33px;
}

.alert_css{
  padding-top: 8px;
  padding-bottom: 6px;
  width: 88%;
  margin-left: 256px;
  text-align: center;
}

/* .stop{width:50%;} */

.custom-select:disabled{
  color: #020202 !important;
  background-color: #eaebec !important;
}

.add_more{
  float: right;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
}

.modalHeader{
  background-color: #31446b !important;
}

.modalContent{
  border-color: #31446b !important;
}


.pie-chart{
  display: block;
  width: 983px !important;
  height: 476px !important;
}

.dashboard_refresh{
  margin-top: -19px;
}

.class1{
  float: left;
  margin-left: 1%;
  margin-top:20px
}

.class2{
  float: right;
  margin-top: -20px;
}


.buttonRight{
  float: right;
}



[type="file"] {
  /* height: 0; */
  overflow: hidden;
  /* width: 0; */
}

.user_profile{
  height: 0;
    overflow: hidden;
    width: 0;
}

[type="file"] + label {
  background: #244da7;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
	font-family: 'Rubik', sans-serif;
	font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  height: 33px;
  padding-top: 8px;
}

.editButton{
  float: right;
    margin-top: 15px;
}


.error_handle{
  margin-left: 46%;
    color: #da5656;
}

.showing_column{
  width: 13%  !important;
}

.pagination_count{
  float: right;
}

.showing_pagination_count{
  width: 50%  !important;
}

.sorting_lebal{
  padding: 15px;
  padding-bottom: 1px;
}


.button_style1{
  float: right;
    margin-right: 1%;
}

.custom-table-head{
  color:red
}



.view_btn {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4;
}


.loading{
  fill: black;
  width: 29px;
  margin-left: 44%;
  z-index: 9px;
  animation: rotate 1.1s cubic-bezier(0, 0, 0.56, 0.26) infinite;
  z-index: 20;
  position: absolute;
  color: #7e79a9 !important;
  width: 33px;
}

.new_user{
  margin-left: 27%;
  font-weight: 900;
}
/* 
.c-side-hover:hover{
  background-color: red
} */


/* .container {
  position: relative;
  width: 50%;
} */

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #f6960b;
  color: white;
  font-size: 16px;
  padding: 16px 16px;
  width: 110%;
}

.secret_key{
  float: left;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.flex-row {
  margin-top: 0px !important;
}

.download_key{
  text-decoration-line: underline;
  cursor: pointer;
  color: #1d4584 !important;
}

.refresh_screte_key{
  float: right;
  font-size: 17px;
  cursor: pointer;
}

.open_api{
  margin-left: 42%;
  color: #2525ad;
  cursor: pointer;
}


.warning_msg{
  font-size: 11px;
    color: #c15c5c;
}


/****************************** Services css **********************************/
.service_header{
  font-weight: 800;
    color: #3c4b64;
    font-size: 16px;
    font-family: roboto;
}

.field_error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;

}

.sub_services_tabs{
  
  margin-right: 5px;
  padding: 7px !important;
  height: unset;
  border-radius: 5px;
  background-color: #198ae3;
  /* background-color: #e6e6e6; */
  margin-top: 10px;
  margin: 0px 17px 0px 0px;
  color:white

}

.trash_event{
  margin-left: 10px;
    cursor: pointer;
}

.duplicate_error{
  color: red;
    font-family: roboto;
}


.fontLabel{
  font-weight: 500;
  font-size: 0.875rem!important;
  color: #3e3e3e;
}

.form-control{
  font-weight: 500;
  color: #3e3e3e;
}

.custom-select{
  font-weight: 500;
  color: #3e3e3e;
}


.table thead th{
  background-color: #3c4b64;
}

thead tr th{
  color: white;
}

th.sortable{
  color: white !important;
}